import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { changePasswordFromToken, lostPassword } from '../../actions/thunks'
import Box from '../layout/Box'
import Field from '../utils/Field'
import Form from '../utils/Form'
import Layout from '../layout/Layout'
import PasswordForm from './user/PasswordForm'
import Title from './Title'
import shielded from '../../hoc/shielded'

@connect(void 0, (dispatch, { history: { push } }) => ({
  onLostPassword: mail => dispatch(lostPassword(mail, push)),
  onChangePasswordFromToken: (token, password) =>
    dispatch(changePasswordFromToken(token, password, push)),
}))
@shielded('layout')
@block
export default class LostPassword extends React.PureComponent {
  render(b) {
    const { location, onLostPassword, onChangePasswordFromToken } = this.props
    const token = location.search.replace('?token=', '')
    return (
      <Layout className={b}>
        <Helmet>
          <title>Mot de passe oublié</title>
        </Helmet>
        <Title>Mot de passe oublié</Title>
        {token ? (
          <Box size="wide">
            <PasswordForm
              onSubmit={item => onChangePasswordFromToken(token, item.password)}
            />
          </Box>
        ) : (
          <Box size="wide">
            <p>
              Après avoir rentré votre adresse mail et valider le formulaire
              vous allez recevoir un mail contenant un lien pour redéfinir votre
              mot de passe.
            </p>
            <Form
              className={b.e('form')}
              noNotifications
              onSubmit={({ mail }) => onLostPassword(mail)}
            >
              <Field name="mail" type="email" required>
                Votre adresse mail
              </Field>
            </Form>
          </Box>
        )}
      </Layout>
    )
  }
}
