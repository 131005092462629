import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { apiState } from '../../../utils'
import { categoriesToTree } from '../../../utils/category'
import Box from '../../layout/Box'
import ProductCategory from './ProductCategory'
import api from '../../../api'
import shielded from '../../../hoc/shielded'

@connect(
  state => ({
    client: state.client,
    category: state.api.category,
  }),
  dispatch => ({
    sync: () => dispatch(api.actions.category.get()),
  })
)
@shielded('box')
@block
export default class ProductCategories extends React.PureComponent {
  componentDidMount() {
    const { sync } = this.props
    sync()
  }

  render(b) {
    const { className, type, currentCategoriesCode, category, onCategory } =
      this.props
    const { objects: rawCategories } = category
    const categories = categoriesToTree(rawCategories, type)
    return (
      <Box className={b.mix(className)} kind="mute" state={apiState(category)}>
        <ProductCategory
          currentCategoriesCode={currentCategoriesCode}
          onCategory={onCategory}
          categoryChildren={categories}
        />
      </Box>
    )
  }
}
