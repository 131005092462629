import { FaTimesCircle } from 'react-icons/fa'
import React from 'react'

import { logError } from '../utils'
import Box from '../components/layout/Box'
import BoxTitle from '../components/utils/BoxTitle'
import Layout from '../components/layout/Layout'
import Push from '../components/utils/Push'

export default function shielded(type) {
  return function (WrappedComponent) {
    return class Protected extends React.PureComponent {
      static WrappedComponent = WrappedComponent

      constructor(props) {
        super(props)
        this.state = { error: false, errorInfo: null }
        this.handleRetry = this.handleRetry.bind(this)
      }

      componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo })
        logError(error, errorInfo)
      }

      handleRetry() {
        this.setState({ error: null, errorInfo: null })
      }

      render() {
        const { error } = this.state

        if (error) {
          const { state, ...props } = this.props
          const errorState = { ...(state || {}), error: true }
          const bug = (
            <>
              <p>
                Un problème s’est produit au chargement de
                {type === 'layout' ? ' cette page' : ' ce composant'}. Notre
                équipe technique a été notifiée et se charge de réparer ce petit
                problème au plus vite.
                <Push kind="mute" onClick={this.handleRetry}>
                  Réessayer
                </Push>
              </p>
            </>
          )
          if (type === 'layout') {
            return (
              <Layout state={errorState} {...props}>
                <Box state={{ error }}>
                  <BoxTitle>
                    <FaTimesCircle />
                    Erreur
                  </BoxTitle>
                  {bug}
                </Box>
              </Layout>
            )
          }
          if (type === 'box') {
            return (
              <Box state={errorState} {...props}>
                <BoxTitle>
                  <FaTimesCircle />
                  Erreur
                </BoxTitle>
                {bug}
              </Box>
            )
          }
          return <div className={type}>{bug}</div>
        }
        return <WrappedComponent {...this.props} />
      }
    }
  }
}
