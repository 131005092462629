import React from 'react'
import { connect } from 'react-redux'

import { show, clear } from '../../actions/notifications'
import { load } from '../../actions/thunks'
import adminApi from '../../api/adminApi'

@connect(
  state => ({
    themes: state.adminApi.themes,
  }),
  dispatch => ({
    sync: () => dispatch(adminApi.actions.themes.get()),
    showMessage: message => {
      dispatch(clear())
      dispatch(show.success(message))
    },
  })
)
export default class ThemeSwitcher extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      themes: [],
      _oldThemes: [],
      index: 0,
      manifest: {},
    }

    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  static getDerivedStateFromProps(
    { themes: { objects: themes } },
    { _oldThemes }
  ) {
    if (themes !== _oldThemes) {
      const newThemes = themes.reduce((allThemes, { key, variants }) => {
        variants.forEach(({ key: variant }) => {
          allThemes.push(`${key}.${variant}`)
        })
        return allThemes
      }, [])
      const themeNode = document.getElementById('theme-css')
      const newIndex = themeNode
        ? newThemes.indexOf(
            themeNode
              .getAttribute('href')
              .split('/')
              .slice(-1)[0]
              .split('.')
              .slice(0, 2)
              .join('.')
          )
        : 0
      return {
        themes: newThemes,
        index: newIndex,
        _oldThemes: themes,
      }
    }
    return null
  }

  componentDidMount() {
    const { sync, showMessage } = this.props
    window.addEventListener('keydown', this.handleKeyDown)
    sync()
    this.syncManifest()
    showMessage('Theme switcher enabled!')
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  async syncManifest() {
    this.setState({ manifest: await load('/assets/manifest.json') })
  }

  syncTheme() {
    const { showMessage } = this.props
    const { index, themes, manifest } = this.state
    const theme = manifest[`${themes[index]}.css`]
    const themeNode = document.getElementById('theme-css')
    showMessage(`Activating ${themes[index]}`)
    themeNode.setAttribute('href', theme)
  }

  handleKeyDown({ shiftKey, keyCode }) {
    const { themes } = this.state
    if (!themes.length) {
      return
    }
    if (shiftKey && keyCode === 37) {
      this.setState(({ index }) => ({
        index: index > 1 ? index - 1 : themes.length - 1,
      }))
      this.syncTheme()
    }
    if (shiftKey && keyCode === 39) {
      this.setState(({ index }) => ({
        index: index < themes.length - 2 ? index + 1 : 0,
      }))
      this.syncTheme()
    }
  }

  render() {
    return null
  }
}
