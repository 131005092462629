import deepequal from 'deep-equal'

import { storage } from '../utils'
import log from '../utils/log'

export default ({ getState }) =>
  next =>
  action => {
    const rv = next(action)

    if (storage && action.type.endsWith('CART')) {
      const oldCart = JSON.parse(storage.getItem('cart'))
      const newCart = getState().cart
      if (!deepequal(oldCart, newCart) && newCart) {
        try {
          storage.setItem('cart', JSON.stringify(newCart))
        } catch (e) {
          // Happen in private mode on some browsers
          log.log(e.message)
        }
      }
    }

    return rv
  }
